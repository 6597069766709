import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      labelClass: { display: 'block' },
    };

    this.handleSubscribe = this.handleSubscribe.bind(this);
    this.moveLabel = this.moveLabel.bind(this);
    this.displayLabel = this.displayLabel.bind(this);
    this.selectText = this.selectText.bind(this);
  }

  handleSubscribe(event) {
    this.context.mixpanel.track('Signup');
    event.target.form.submit();
  }

  moveLabel(event) {
    if (event.target.value.length > 0) {
      this.setState({ labelClass: { display: 'none' } });
    } else {
      this.setState({ labelClass: { display: 'block' } });
    }
  }

  selectText(event) {
    event.target.select();
  }

  displayLabel(event) {
    if (event.target.value.length === 0) {
      this.setState({ labelClass: { display: 'block' } });
    }
  }

  render() {
    return (
      <div className="c-header-container">
        <form
          action="https://nycode.us8.list-manage.com/subscribe/post?u=ecb050263d8d76b7d12caca00&amp;id=1d4b3156de"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          target="_blank"
          noValidate
        >
          <div className="c-header">
            <div className="c-header__bottom">
              <h1>ui_mode</h1>
              <h2>Five links each week about making things on the web.</h2>
              <div className="c-header__input-container">
                <input
                  id="email-input"
                  type="email"
                  name="EMAIL"
                  className="c-header__input-field"
                  onFocus={this.selectText}
                  onChange={this.moveLabel}
                  onBlur={this.displayLabel}
                />
                <label htmlFor="email-input" id="email-label" style={this.state.labelClass}>
                  Your email here
                </label>
              </div>
              <button className="c-header__button" onClick={this.handleSubscribe}>
                Subscribe
              </button>
              <div
                style={{
                  position: 'absolute',
                  left: '-5000px',
                }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_ecb050263d8d76b7d12caca00_1d4b3156de"
                  tabIndex="-1"
                  value=""
                  onChange={function(event) {
                    return true;
                  }}
                />
              </div>
            </div>
            <footer>
              © 2018-{new Date().getFullYear()}{' '}
              <a href="https://christopherandersson.com">Christopher Andersson</a>
            </footer>
          </div>
        </form>
      </div>
    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

Header.contextTypes = {
  // mixpanel must be declared on contextTypes
  mixpanel: PropTypes.object.isRequired,
};

export default Header;
