import React from 'react';
import './styles.scss';
import unique from 'uniqid';

const Lines = (props) => {
  const markup = props.total.map(() => (
    <b
      key={unique()}
      style={{
        borderBottom: '1px solid red',
      }}
    />
  ));

  return (
    <div
      className="grid"
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridAutoRows: '24px',
      }}
    >
      {markup}
    </div>
  );
};

class Grid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gridLines: [0],
    };
  }

  renderLines = () => {
    let lines = new Array(Math.floor(window.innerHeight / 24));
    lines.fill(0);
    this.setState({ gridLines: lines });
  };

  componentDidMount() {
    window.addEventListener('resize', this.renderLines);
    this.renderLines();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.renderLines);
  }

  render() {
    return <div>{this.props.show && <Lines total={this.state.gridLines} />}</div>;
  }
}

export default Grid;
